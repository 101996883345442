import {
	Course,
	Focusarea,
	Focusareaoption,
	Optionslist,
	Outcome,
	Syllabus,
} from '@/kontent/content-types'
import { Mapping, TaxoStageWithLifeSkill } from '@/types'
import {
	byTaxoCodename,
	getSlugByCodename,
	getTaxoCodenames,
	isYes,
} from '@/utils'
import { isLifeSkillFocusAreaOrOptionListOrOutcome } from '@/utils/focusarea'
import { getSyllabusUrlFromMappingByTaxo } from '@/utils/getSyllabusUrlFromMapping'
import { getSlugByCodenameYear } from '@/utils/slug-year-utils'
import { isFocusarea } from '@/utils/type_predicates'
import { Elements, IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import { ReactNode, createContext, useContext } from 'react'
import { getLinkedItems } from '../contexts/KontentHomeConfigProvider'

export interface ExtendedFocusarea extends Focusarea {
	/**
	 * Link on content tab
	 */
	contentTabUrl: string
}

export interface ExtendedOutcome extends Outcome {
	alignedFocusAreas: ExtendedFocusarea[]
	mappedOutcomeSyllabusCode?: string[]
	relatedOutcomesAlignedFocusAreas?: Record<string, ExtendedFocusarea[]>
}

export interface ExtendedFocusareaoption extends Focusareaoption {
	optionList: Optionslist
}

export interface KontentOutcomeWrapperProps {
	/**
	 * Outcomes array
	 */
	outcomes: Outcome[]

	/**
	 * Focus areas array
	 */
	focusAreas: (Focusarea | Optionslist)[]

	/**
	 * Mappings array to generate links
	 */
	mappings: Mapping[]

	/**
	 * Linked items (from the page response, that contain related outcomes)
	 */
	linkedItems: IContentItemsContainer

	/**
	 * The render function
	 */
	children?: (_outcomes: ExtendedOutcome[]) => ReactNode
}

interface OutcomesProviderProps {
	syllabus: Syllabus
	outcomes: ExtendedOutcome[]
	focusAreas?: (Focusarea | Optionslist)[]
	syllabusLinkedItems: IContentItemsContainer
	children?: ReactNode
}
interface CourseElement {
	elements: {
		course: Elements.LinkedItemsElement<Course>
	}
}

export const OutcomeContext = createContext<
	Omit<OutcomesProviderProps, 'children'>
>({
	syllabus: null,
	outcomes: [],
	syllabusLinkedItems: null,
})

export const useOutcomeContext = () => useContext(OutcomeContext)

export const OutcomeProvider = ({
	syllabus,
	outcomes,
	focusAreas = [],
	syllabusLinkedItems,
	children,
}: OutcomesProviderProps) => {
	const value = {
		syllabus,
		outcomes,
		syllabusLinkedItems,
		focusAreas,
	}

	return (
		<OutcomeContext.Provider value={value}>
			{children}
		</OutcomeContext.Provider>
	)
}

const getStagePathForContentAlign = (
	outcome: Outcome,
): TaxoStageWithLifeSkill => {
	const isLifeSkillOutcome =
		isLifeSkillFocusAreaOrOptionListOrOutcome(outcome)

	if (isLifeSkillOutcome) {
		return 'life_skills'
	}

	return outcome.elements.stages__stages.value[0].codename
}

export const getAlignedFocusAreasOrFaOptions = (
	outcome: Outcome,
	focusAreaOrFaOptions: (Focusarea | ExtendedFocusareaoption)[],
	mappings: Mapping[],
	linkedItems?: IContentItemsContainer,
	syllabusCoursesDetails?: SyllabusCoursesDetailsType[],
) => {
	const _focusAreasThatHaveOutcome = focusAreaOrFaOptions.filter(
		(focusArea) => {
			return focusArea.elements.outcomes.value.includes(
				outcome.system?.codename,
			)
		},
	)

	return _focusAreasThatHaveOutcome.map((focusArea) => {
		// /learning-area/[kla]/[syllabus]/[content]/[stage/year]/[focusarea]

		const syllabusPath = getSyllabusUrlFromMappingByTaxo(
			mappings,
			outcome.elements.syllabus.value?.[0]?.codename,
			false,
			true,
		)

		let queryStrings = ['content']
		let contentTabUrl = `${syllabusPath}`

		// Stage/year slug
		const qStage = getStagePathForContentAlign(outcome)
		if (
			outcome.elements.stages__stages.value
				.map(byTaxoCodename)
				.some((stage) => stage === 'stage_6') &&
			qStage === 'stage_6'
		) {
			if (linkedItems && syllabusCoursesDetails) {
				const focusAreaWithCourse = focusArea as Focusarea &
					CourseElement
				const courseItem: Course[] = getLinkedItems(
					focusAreaWithCourse.elements.course,
					linkedItems,
				)
				const isExtensionCourseItem =
					courseItem?.length &&
					isYes(courseItem[0].elements.display_mode) &&
					courseItem[0].elements.content_accordion_title.value
						.length > 0
				if (isExtensionCourseItem) {
					queryStrings.push(
						`${getSlugByCodenameYear(
							getTaxoCodenames(
								outcome.elements.stages__stage_years,
							)[0],
						)}-${courseItem[0].elements.slug.value}`,
					)
				} else {
					queryStrings.push(
						`${getSlugByCodenameYear(
							getTaxoCodenames(
								outcome.elements.stages__stage_years,
							)[0],
						)}`,
					)
				}
			} else {
				queryStrings.push(
					`${getSlugByCodenameYear(
						getTaxoCodenames(
							outcome.elements.stages__stage_years,
						)[0],
					)}`,
				)
			}
		} else {
			queryStrings.push(qStage)
		}

		if (!isFocusarea(focusArea)) {
			// Focus area option slug
			queryStrings.push(focusArea.optionList.system.codename)
		}
		queryStrings.push(focusArea.system.codename)

		contentTabUrl = `${syllabusPath}/${queryStrings
			.map(getSlugByCodename)
			.join('/')}`

		return {
			...focusArea,
			contentTabUrl,
		} as ExtendedFocusarea
	})
}

export type SyllabusCoursesDetailsType = {
	stage_year: {
		codename: string
		name: string
	}
	courseCode: string
}

/**
 * Wrapper component to solve the circular reference relatedlifeskilloutcomes issue
 */
export const KontentOutcomeWrapper = ({
	outcomes,
	focusAreas: focusAreasOrOptionlist,
	mappings,
	linkedItems,
	children,
}: KontentOutcomeWrapperProps) => {
	const focusAreaOrFaOptions = focusAreasOrOptionlist.flatMap<
		Focusarea | ExtendedFocusareaoption
	>((faOrOl) => {
		if (isFocusarea(faOrOl)) {
			return faOrOl
		}
		const focusAreaOptions = getLinkedItems(
			faOrOl.elements.focus_area_options,
			linkedItems,
		)
		return focusAreaOptions.map<ExtendedFocusareaoption>((faOption) => {
			return {
				...faOption,
				optionList: faOrOl,
			}
		})
	})

	const _outcomes = outcomes.map((outcome) => {
		const relatedLifeSkillOutcomes = getLinkedItems(
			outcome.elements.relatedlifeskillsoutcomes,
			linkedItems,
		)

		const mappedOutcome = focusAreaOrFaOptions.filter((item) => {
			return item.elements.outcomes.value.includes(
				outcome.system?.codename,
			)
		})

		const syllabusCoursesDetails = focusAreaOrFaOptions
			.map(
				(
					item: Focusarea | ExtendedFocusareaoption,
				): SyllabusCoursesDetailsType => {
					return {
						stage_year: item.elements.stages__stage_years.value[0],
						courseCode: (item as Focusarea & CourseElement).elements
							.course?.value[0],
					}
				},
			)
			.filter(
				(obj1, i, arr) =>
					arr.findIndex(
						(obj2) => obj2.courseCode === obj1.courseCode,
					) === i,
			)

		return {
			...outcome,
			alignedFocusAreas: getAlignedFocusAreasOrFaOptions(
				outcome,
				focusAreaOrFaOptions,
				mappings,
				linkedItems,
				syllabusCoursesDetails,
			),
			mappedOutcomeSyllabusCode: mappedOutcome
				.map(
					(item) =>
						(item as Focusarea & CourseElement).elements.course
							?.value,
				)
				.flat(),
			relatedOutcomesAlignedFocusAreas: relatedLifeSkillOutcomes.reduce(
				(acc, curr) => {
					return {
						...acc,
						[curr.system.codename]: getAlignedFocusAreasOrFaOptions(
							curr,
							focusAreaOrFaOptions,
							mappings,
							linkedItems,
							syllabusCoursesDetails,
						),
					}
				},
				{},
			),
		} as ExtendedOutcome
	})

	return <>{children ? children(_outcomes) : null}</>
}
