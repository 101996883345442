export const waitUntil = (
	conditionFn: Function,
	callbackWhenConditionTrue: Function,
) => {
	var fnCheckCondition = function () {
		if (!conditionFn()) {
			requestAnimationFrame(fnCheckCondition)
			return
		}
		callbackWhenConditionTrue()
		return
	}
	requestAnimationFrame(fnCheckCondition)
}
