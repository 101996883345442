import { useAssetsFromAssetsElement } from '@/components/contexts/AssetsProvider'
import { useIsScreenDown } from '@/hooks/useIsScreenDown'
import { UiResources as UiResourcesModel } from '@/kontent/content-types'
import DownloadList, {
	DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST,
	DownloadListField,
} from '@/legacy-ported/components/syllabus/DownloadList'
import { useMemo } from 'react'
import type { RichtextSectionProps } from '.'

const CustomHiddenDownloadListFields: DownloadListField[] = [
	...DEFAULT_HIDDENS_FOR_DOWNLOAD_LIST,
	'stage',
	'resourceType',
	'year',
]

export default function UiResources({
	linkedItem,
}: RichtextSectionProps<UiResourcesModel>) {
	const isScreenDownMd = useIsScreenDown('md')
	const isScreenDownLg = useIsScreenDown('lg')

	const hiddenFieldsForDownloadList = useMemo<DownloadListField[]>(() => {
		const hiddenOnDownLg: DownloadListField[] = [
			'resourceType',
			'stage',
			'year',
		]

		if (isScreenDownMd) {
			return [
				...CustomHiddenDownloadListFields,
				...hiddenOnDownLg,
				'fileSize',
				'fileType',
			]
		}
		if (isScreenDownLg) {
			return [...CustomHiddenDownloadListFields, ...hiddenOnDownLg]
		}
		return CustomHiddenDownloadListFields
	}, [isScreenDownMd, isScreenDownLg])

	const files = useAssetsFromAssetsElement(linkedItem.elements.resources)
	return (
		<div className="">
			<DownloadList
				files={files}
				hiddenFields={hiddenFieldsForDownloadList}
				hideCheckbox={true}
			/>
		</div>
	)
}
