import { commonPageApiFetchWithCodename } from '@/utils/page-api/commonPageApiFetchWithCodename'
import { ILink } from '@kontent-ai/delivery-sdk'
import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'
import { Loading } from '../Loading'
import { LinkPropsExtended } from './RichtextLinkWrapper'

interface RichtextLinkWeblinkCommonProps {
	link: ILink
	children: (_: LinkPropsExtended) => ReactNode
}

export const RichtextLinkWeblinkCommon = ({
	link,
	children,
}: RichtextLinkWeblinkCommonProps) => {
	const { data, isFetched } = useQuery({
		queryKey: ['RichtextLinkWeblinkCommon', link.codename],
		queryFn: () => {
			return commonPageApiFetchWithCodename<{
				url: string
			}>({
				codename: link.codename,
				pageApiSlug: link.type,
			})
		},
		staleTime: Infinity,
	})

	if (!isFetched) {
		return <Loading />
	}

	if (isFetched && !data) {
		return null
	}

	// Find the link in the data
	const theLink = data.pageProps
	if (!theLink?.url) {
		return null
	}

	return (
		<>
			{children({
				href: theLink.url,
				scroll: true,
				target: link.type === 'weblinkext' ? '_blank' : undefined,
			})}
		</>
	)
}
