// for reference: https://digital.nsw.gov.au/digital-service-toolkit/design-system/digital-styles/colour
const DESIGN = {
	COLOR_BLUE_PRIMARY: '#002664',
	COLOR_BLUE_SECONDARY: '#002664',
	COLOR_BLUE_TERTIARY: '#0085B3',
	COLOR_RED_PRIMARY: '#D7153A',
	COLOR_WHITE: '#FFFFFF',
	COLOR_LIGHT10: '#F4F4F7',
	COLOR_LIGHT15: '#EAEDF4',
	COLOR_LIGHT17_5: '#EBEBEB',
	COLOR_LIGHT20: '#E4E4E6',
	COLOR_LIGHT40: '#A0A5AE',
	COLOR_DARK60: '#6D7079',
	COLOR_DARK65: '#495054',
	COLOR_DARK70: '#4C4F55',
	COLOR_DARK80: '#333333',
	COLOR_BLACK: '#000000',
	COLOR_NOTIFICATION_INFO_BLUE: '#2E5299',
	COLOR_NOTIFICATION_SUCCESS_GREEN: '#00A908',
	COLOR_NOTIFICATION_WARNING_ORANGE: '#DC5800',
	COLOR_NOTIFICATION_ERROR_RED: '#B81237',
}
export const FONT_SIZES = {
	HEADING: '28px',
	HEADING_LINE_HEIGHT: '38px',
	SUB_HEADING: '23px',
	SUB_HEADING_LINE_HEIGHT: '33px',
	PARAGRAPH: '14px',
	PARAGRAPH_LINE_HEIGHT: '19px',
}
export default DESIGN
