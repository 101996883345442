const SYLLABUS = {
	FILTER: {
		ACCESS_POINTS: 'Access content points',
		TEACHING_SUPPORT: 'Teaching advice',
		TAGS: 'Tags',
		EXAMPLES: 'Examples',
		CURRICULUM_CONNECTIONS: 'Curriculum Connections',
	},
	TAB: {
		COURSE_DESCRIPTION: 0,
		RATIONALE: 1,
		AIM: 2,
		OUTCOME: 3,
		CONTENT_ORGANISERS: 4,
		COURSE_PERFORMANCE: 5,
		GLOSSARY: 6,
		TEACHING_AND_LEARNING: 7,
	},
	CONTENT_DEFAULT_OFFSET_TOP: {
		LEARNING_AREA: 632,
		STAGES: 152,
	},
	COMPARE_OUTCOME_SCROLL_OFFSET: {
		LEARNING_AREA: 425,
		STAGES: 415,
		CUSTOM_PAGE: 325,
	},
	NAVIGATION_TYPE: {
		NEXT: 'NEXT',
		PREVIOUS: 'PREVIOUS',
	},
}

export default SYLLABUS
