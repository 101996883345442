import { CommonPageAPIType, PAGE_API_BASE_PATH } from '.'
import { fnExist } from '..'
import { commonFetch } from '../fetchUtils'

export const commonPageApiFetchWithCodename = async <T = { url: string }>({
	codename,
	pageApiSlug,
}: {
	codename: string
	pageApiSlug: string
}) => {
	let paths = [codename].filter(fnExist).join('/')

	const { ok, json } = await commonFetch<CommonPageAPIType<T>, null>(
		`${PAGE_API_BASE_PATH}/page-api/${pageApiSlug}/${paths}.json`,
		null,
		{
			method: 'GET',
		},
	)

	if (ok) {
		return json
	}
}
