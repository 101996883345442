import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { YEARS } from '@/constants'
import { Course } from '@/kontent/content-types'
import { TaxoStageYear } from '@/kontent/taxonomies'
import { TaxoStageGroup } from '@/kontent/taxonomies/stage_group'
import { TaxoStageWithLifeSkill } from '@/types'
import type { ElementModels, ITaxonomyTerms } from '@kontent-ai/delivery-sdk'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import { arrayToggleMultiple } from '../../utilities/functions'
import CustomPopover, { CustomPopoverProps } from '../base/CustomPopover'
import { YearPicker } from '../custom/YearPicker'
export interface YearSelectOverlayProps
	extends Pick<
		CustomPopoverProps,
		'popoverStatus' | 'popoverAnchor' | 'onCancel' | 'anchorOrigin'
	> {
	title?: string
	selected: ITaxonomyTerms['codename'][]
	stageGroups: ElementModels.TaxonomyTerm<TaxoStageGroup>[]
	stages: ElementModels.TaxonomyTerm<TaxoStageWithLifeSkill>[]
	onConfirm: (_selected: ITaxonomyTerms['codename'][]) => void
	syllabusCourses?: Course[]
}

export const YearSelectOverlay = (
	props: YearSelectOverlayProps,
): JSX.Element => {
	const { selected, onConfirm, stageGroups, stages, ...popoverProps } = props

	const [yearIds, setYearIds] = useState(selected)
	const [stageError, setStageError] = useState(false)

	const handleStageModalConfirm = () => {
		if (yearIds.length >= 1) {
			onConfirm(yearIds)
			setStageError(false)
		} else {
			setStageError(true)
		}
	}

	const handleStageSelect = (ids: string[]) => {
		setYearIds(arrayToggleMultiple(yearIds, ids))
	}

	const syllabusAccordionYearCodenames = props.syllabusCourses?.map(
		(item) => {
			return item.elements.content_accordion.value[0].codename
		},
	)

	const extensionCodenames = syllabusAccordionYearCodenames.filter(
		(e, i, a) => a.indexOf(e) !== i,
	)

	const syllabusCourseOptions = props.syllabusCourses?.map((course) => {
		const isExtension = extensionCodenames.includes(
			course.elements.content_accordion.value[0].codename,
		)
		return {
			label: isExtension
				? `${course.elements.content_accordion.value[0].name}${course.elements.content_accordion_title.value}`
				: course.elements.content_accordion.value[0].name,
			value: isExtension
				? `${course.elements.content_accordion.value[0].codename}-${course.elements.slug.value}`
				: course.elements.content_accordion.value[0].codename,
		}
	}) as unknown as ElementModels.TaxonomyTerm<TaxoStageYear>[]

	return (
		<CustomPopover onConfirm={handleStageModalConfirm} {...popoverProps}>
			<Grid className="w-full">
				<GridWrapper>
					<YearPicker
						years={
							syllabusCourseOptions &&
							syllabusCourseOptions.length > 0
								? (syllabusCourseOptions as unknown as ElementModels.TaxonomyTerm<TaxoStageYear>[])
								: YEARS
						}
						stageGroups={stageGroups}
						stages={stages}
						selected={yearIds}
						onChange={handleStageSelect}
						syllabusCoursesSelections={syllabusCourseOptions}
					/>
					{stageError && (
						<Grid item xs={12}>
							<span className="syllabus-header-dialog__error">
								Please select at least 1 year
							</span>
						</Grid>
					)}
				</GridWrapper>
			</Grid>
		</CustomPopover>
	)
}
