import { WebLinkSyllabus as TWebLinkSyllabus } from '@/kontent/content-types'
import { getSlugByCodename } from '@/utils'
import Link from 'next/link'
import { RichtextSectionProps } from '.'
import {
	getLinkedItems,
	useKontentHomeConfig,
} from '../contexts/KontentHomeConfigProvider'

const WebLinkSyllabus = ({
	linkedItem,
}: RichtextSectionProps<TWebLinkSyllabus>) => {
	const { pageResponseLinkedItems } = useKontentHomeConfig()

	const syllabuses = getLinkedItems(
		linkedItem.elements.item,
		pageResponseLinkedItems,
	)
	const additionalPage = getLinkedItems(
		linkedItem.elements.additional_page,
		pageResponseLinkedItems,
	)
	const syllabus = syllabuses?.[0]
	if (!syllabus) {
		return <span>{linkedItem.elements.title.value}</span>
	}

	const tab = linkedItem.elements.tab.value?.[0]?.codename

	let tabSlug = tab ? getSlugByCodename(tab) : 'overview'
	if (
		tabSlug.includes('overview') ||
		tabSlug === 'course-description' ||
		tabSlug === 'additional-page'
	) {
		tabSlug = 'overview'
	}

	let sectionSlug = ''
	if (tabSlug === 'overview') {
		sectionSlug = additionalPage?.[0]?.elements.slug.value || ''
		if (tab === 'course_description') {
			sectionSlug = 'course'
		}
	}

	const syllabusPath = [
		'/learning-areas',
		getSlugByCodename(
			syllabus.elements.key_learning_area_default.value?.[0]?.codename ||
				'',
		), // learning area slug
		getSlugByCodename(linkedItem.elements.item.value?.[0] || ''), // syllabus slug
		tabSlug, // tab slug
		sectionSlug,
	].join('/')

	return (
		<Link href={syllabusPath}>
			<a>{linkedItem.elements.title.value}</a>
		</Link>
	)
}

export default WebLinkSyllabus
