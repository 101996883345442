import { getCodenameBySlug } from './getCodenameBySlug'
import { getSlugByCodename } from './getSlugByCodename'

export const getCodenameBySlugYear = (slug: string) => {
	let _slug = slug
	// replace `year-` to `n`
	if (new RegExp(/^year-\d+/).test(slug)) {
		_slug = slug.replace('year-', 'n')
	}
	return getCodenameBySlug(_slug)
}

export const getSlugByCodenameYear = (codename: string) => {
	let _codename = codename
	if (new RegExp(/^n\d+/).test(codename)) {
		_codename = codename.replace('n', 'year_')
	}
	return getSlugByCodename(_codename)
}
