import { GridCol } from '@/components/nsw/grid/GridCol'
import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { SideNav } from '@/components/nsw/side-nav/SideNav'
import { overviewNavStyle } from '@/components/syllabus-tabs/Overview'
import { Syllabus } from '@/kontent/content-types'
import type { Mapping } from '@/types'
import {
	getLinkedItems,
	isRichtextElementEmpty,
	isRichtextEmpty,
} from '@/utils'
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import parseHTML, { Element, domToReact } from 'html-react-parser'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import slugify from 'slugify'
import InPageNav from '../InPageNav'
import RichText from '../RichText'
import { HeadingComponent } from '../RichTextComponent'

export type SyllabusSectionOverViewProps = {
	mappings: Mapping[]
	linkedItems: IContentItemsContainer
	syllabus: Syllabus
}

export const SyllabusSectionOverview = ({
	linkedItems,
	mappings,
	syllabus,
}: SyllabusSectionOverViewProps) => {
	const [sectionState, setSectionState] = useState<string>('')

	const router = useRouter()

	const staticSideNavItems = [
		{
			text: 'Syllabus overview',
			href: '#',
			isActive: sectionState == '',
			onClick: (e) => handleSideNavClick(e, ''),
		},
		{
			text: 'Course description',
			href: '#',
			isActive: sectionState == 'course',
			onClick: (e) => handleSideNavClick(e, 'course'),
		},
	]

	const [, setIsCourseFooterNodesSet] = useState(false)

	const refCourseFooterNodes = useRef<string | JSX.Element | JSX.Element[]>(
		[],
	)

	const handleSideNavClick = (
		e: React.MouseEvent<HTMLAnchorElement>,
		val: string,
	) => {
		e.preventDefault()
		setSectionState(val)
	}

	const sideNavItems = getLinkedItems(
		syllabus.elements.additional_content,
		linkedItems,
	)

	const overviewCourseItems = getLinkedItems(
		syllabus.elements.courses,
		linkedItems,
	).filter((a) => a.elements.display_overview.value[0].name === 'Yes')

	const hideCourseTab =
		overviewCourseItems.length == 0 &&
		isRichtextEmpty(syllabus.elements.course_content.value)

	const currentSectionTitle =
		sectionState == 'course' || !sectionState
			? staticSideNavItems.find((item) => item.isActive).text
			: sideNavItems?.find(
					(item) => item.elements.slug.value === sectionState,
			  )?.elements.title.value

	const richTextContent =
		sectionState.length == 0
			? syllabus.elements.web_content_rtb__content
			: sectionState == 'course'
			? syllabus.elements.course_content
			: sideNavItems?.find((item) => {
					return item.elements.slug.value === sectionState
			  })?.elements.content

	return (
		<>
			<h2>Overview</h2>
			<GridWrapper className="mt-0">
				<GridCol lg={3}>
					<SideNav
						css={overviewNavStyle}
						className="block"
						items={[
							...staticSideNavItems.filter((a) => {
								return hideCourseTab
									? a.text !== 'Course description'
									: true
							}),
							...sideNavItems.map((item) => {
								return {
									text: item.elements.title.value,
									href: '#',
									isActive:
										item.elements.slug.value ===
										sectionState,
									onClick: (e) =>
										handleSideNavClick(
											e,
											item.elements.slug.value,
										),
								}
							}),
						]}
					/>
				</GridCol>
				<GridCol lg={9}>
					<div key={syllabus.system.id}>
						<h3 className="mb-8">{currentSectionTitle}</h3>
						<RichText
							currentPath={router.asPath}
							mappings={mappings}
							data-kontent-item-id={syllabus.system.id}
							data-kontent-element-codename="web_content_rtb__content"
							linkedItems={linkedItems}
							className="cms-content-formatting"
							richTextElement={richTextContent}
							suffixForHeading={syllabus.system.codename}
							renderFnBefore={(nodes) => {
								const courseHeadings = overviewCourseItems?.map(
									(item) => {
										const text = `Further information for ${item.elements.name.value}`
										let id = slugify(
											item.elements.slug?.value,
											{ lower: true },
										)

										return parseHTML(`<h4>${text}</h4>`, {
											replace: (domNode: Element) => {
												return (
													<HeadingComponent
														as={domNode.name}
														id={id}
														attributes={null}
														disableCopyUrl={true}
													>
														{domToReact(
															domNode.children,
														)}
													</HeadingComponent>
												)
											},
										})
									},
								)
								let combinedHeadings =
									sectionState !== 'course'
										? (nodes as JSX.Element)
										: []

								if (sectionState == 'course') {
									if (
										isRichtextElementEmpty(
											syllabus.elements.course_content,
										) &&
										isRichtextElementEmpty(
											syllabus.elements
												.course_content_footer,
										)
									) {
										combinedHeadings = courseHeadings
									} else {
										combinedHeadings = [
											nodes as JSX.Element[],
											courseHeadings,
											refCourseFooterNodes.current,
										].flat()
									}
								}
								return (
									<InPageNav
										richTextElements={
											combinedHeadings as JSX.Element[]
										}
										key={sectionState}
										className="!mt-0"
									/>
								)
							}}
							resolveCourseItems={
								overviewCourseItems.length > 0 &&
								sectionState == 'course'
									? overviewCourseItems
									: null
							}
						/>
						{!isRichtextEmpty(
							syllabus.elements.course_content_footer.value,
						) &&
							sectionState === 'course' && (
								<RichText
									className="mt-8 cms-content-formatting"
									richTextElement={
										syllabus.elements.course_content_footer
									}
									data-kontent-item-id={syllabus.system.id}
									data-kontent-element-codename="course_content_footer"
									linkedItems={linkedItems}
									mappings={mappings}
									renderFnBefore={(nodes) => {
										refCourseFooterNodes.current = nodes
										setIsCourseFooterNodesSet(true)
										return null
									}}
								/>
							)}
					</div>
				</GridCol>
			</GridWrapper>
		</>
	)
}
