import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({})

export interface NextLinkComposedProps
	extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
		Omit<
			NextLinkProps,
			'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'
		> {
	to: NextLinkProps['href']
	linkAs?: NextLinkProps['as']
}

export const NextLinkComposed = React.forwardRef<
	HTMLAnchorElement,
	NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
	const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } =
		props

	return (
		<NextLink
			href={to}
			prefetch={prefetch}
			as={linkAs}
			replace={replace}
			scroll={scroll}
			shallow={shallow}
			passHref
			locale={locale}
		>
			<Anchor ref={ref} {...other} />
		</NextLink>
	)
})

export type NextMuiLinkProps = {
	activeClassName?: string
	as?: NextLinkProps['as']
	href: NextLinkProps['href']
	linkAs?: NextLinkProps['as'] // Useful when the as prop is shallow by styled().
	noLinkStyle?: boolean
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
	Omit<MuiLinkProps, 'href'>

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const NextMuiLink = React.forwardRef<HTMLAnchorElement, NextMuiLinkProps>(
	function Link(props, ref) {
		const {
			activeClassName = 'active',
			as,
			className: classNameProps,
			href,
			linkAs: linkAsProp,
			locale,
			noLinkStyle,
			prefetch,
			replace,
			role, // Link don't have roles.
			scroll,
			shallow,
			...other
		} = props

		const router = useRouter()
		const pathname = typeof href === 'string' ? href : href.pathname
		const className = clsx(classNameProps, {
			[activeClassName]: router.pathname === pathname && activeClassName,
		})

		const isExternal =
			typeof href === 'string' &&
			(href.indexOf('http') === 0 || href.indexOf('mailto:') === 0)

		if (isExternal) {
			if (noLinkStyle) {
				return (
					<Anchor
						className={className}
						href={href}
						ref={ref}
						target="_blank"
						{...other}
					/>
				)
			}

			return (
				<MuiLink
					className={className}
					href={href}
					ref={ref}
					target="_blank"
					{...other}
				/>
			)
		}

		const linkAs = linkAsProp || as
		const nextjsProps = {
			to: href,
			linkAs,
			replace,
			scroll,
			shallow,
			prefetch,
			locale,
		}

		if (noLinkStyle) {
			return (
				<NextLinkComposed
					className={className}
					ref={ref}
					{...nextjsProps}
					{...other}
				/>
			)
		}

		return (
			<MuiLink
				component={NextLinkComposed}
				className={className}
				ref={ref}
				{...nextjsProps}
				{...other}
			/>
		)
	},
)

export default NextMuiLink
