import RichText from '@/components/RichText'
import { HeadingComponent } from '@/components/RichTextComponent'
import { Course } from '@/kontent/content-types'
import type { Mapping } from '@/types'
import { getLinkedItems } from '@/utils'
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import slugify from 'slugify'

export type CoursesListingProps = {
	courseItems: Course[]
	mappings: Mapping[]
	linkedItems: IContentItemsContainer
}

export const CoursesListing = ({
	courseItems,
	linkedItems,
	mappings,
}: CoursesListingProps) => {
	return (
		<div>
			{courseItems &&
				courseItems.map((item) => {
					const courseDetails = getLinkedItems(
						item.elements.content,
						linkedItems,
					)
					return (
						<div key={item.system.id} className="my-16">
							<HeadingComponent
								as={'h4'}
								id={slugify(item.elements.slug?.value, {
									lower: true,
								})}
								attributes={{
									className: 'my-4 md:mt-8 md:mb-5',
								}}
								disableCopyUrl={false}
							>
								Further information for{' '}
								{item.elements.name.value}
							</HeadingComponent>

							<div className="grid md:grid-cols-2 border p-4 md:p-8 rounded-md my-4 md:mt-5 md:mb-8 richtext">
								<div>
									<ul>
										{item.elements.courseid.value.length >
											0 && (
											<li>
												Course number:{' '}
												{item.elements.courseid.value}
											</li>
										)}
										{item.elements.coursehours.value
											.length > 0 && (
											<li>
												Course hours:{' '}
												{
													item.elements.coursehours
														.value[0]?.name
												}
											</li>
										)}
										{item.elements.courseunits.value
											.length > 0 && (
											<li>
												Course units:{' '}
												{
													item.elements.courseunits
														.value[0].name
												}
											</li>
										)}
									</ul>
								</div>
								<div className="mt-0">
									<ul>
										{item.elements.hscexaminable.value
											.length > 0 && (
											<li>
												HSC Examinable:{' '}
												{
													item.elements.hscexaminable
														.value[0].name
												}
											</li>
										)}
										{item.elements.enrolmenttype.value
											.length > 0 && (
											<li>
												Enrolment type:{' '}
												{
													item.elements.enrolmenttype
														.value[0].name
												}
											</li>
										)}
										{item.elements.endorsementtype.value
											.length > 0 && (
											<li>
												Endorsement type:{' '}
												{
													item.elements
														.endorsementtype
														.value[0].name
												}
											</li>
										)}
										{item.elements.selftuition.value
											.length > 0 && (
											<li>
												Study via self-tuition:{' '}
												{
													item.elements.selftuition
														.value[0].name
												}
											</li>
										)}
									</ul>
								</div>
							</div>
							{courseDetails &&
								courseDetails.map((course) => {
									const links =
										course.elements.links.value.length > 0
											? getLinkedItems(
													course.elements.links,
													linkedItems,
											  )
											: null
									return (
										<>
											<h5 className="my-3">
												{
													course.elements.section
														?.value[0]?.name
												}
											</h5>
											<div className="mb-6">
												<RichText
													key={course.system.id}
													mappings={mappings}
													linkedItems={linkedItems}
													className="cms-content-formatting"
													richTextElement={
														course.elements.content
													}
												/>
												{links && (
													<div className="richtext">
														<ul>
															{links.map(
																(link) => {
																	const stageYearString =
																		link.elements.stage_years.value
																			.map(
																				(
																					a,
																				) =>
																					'Year ' +
																					a.name,
																			)
																			.join(
																				', ',
																			)

																	const linkText = `${
																		link
																			.elements
																			.courseid
																			.value
																	} - ${
																		link
																			.elements
																			.name
																			.value
																	} (${
																		link
																			.elements
																			.courseunits
																			.value
																			.length
																			? link
																					.elements
																					.courseunits
																					.value[0]
																					.name +
																			  '  units - '
																			: ''
																	}${stageYearString})`
																	const url = `/learning-areas/${
																		link
																			.elements
																			.syllabus_taxo
																			?.value[0]
																			?.codename
																	}/overview/course${
																		link
																			.elements
																			.slug
																			.value
																			.length >
																		0
																			? '#' +
																			  link
																					.elements
																					.slug
																					.value
																			: ''
																	}`
																	return (
																		<li
																			key={
																				link
																					.system
																					.id
																			}
																		>
																			<a
																				href={
																					url
																				}
																				rel="noreferrer"
																			>
																				{
																					linkText
																				}
																			</a>
																		</li>
																	)
																},
															)}
														</ul>
													</div>
												)}
											</div>
										</>
									)
								})}
						</div>
					)
				})}
		</div>
	)
}
