import OutcomePaper from '@/components/papers/outcome/OutcomePaper'
import { Course, Outcome } from '@/kontent/content-types'
import { TaxoStageYear } from '@/kontent/taxonomies'
import {
	IPropWithClassNameChildren,
	TaxoStageWithLifeSkill,
	TaxoStageYearWithLifeSkill,
} from '@/types'
import {
	compareValueWithMultipleChoiceCodename,
	getFnSortStagesOnTaxoStages,
	getFnSortYears,
	isYes,
} from '@/utils'
import { ElementModels } from '@kontent-ai/delivery-sdk'
import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ButtonIcon } from './nsw/button/ButtonIcon'
import { GridCol } from './nsw/grid/GridCol'
import { GridWrapper } from './nsw/grid/GridWrapper'
import { ExtendedOutcome } from './outcomes/KontentOutcomeWrapper'
import { OutcomeNonOverarchingGrid } from './outcomes/OutcomeNonOverarchingGrid'

export interface OutcomeSliderProps extends IPropWithClassNameChildren {
	pageSize?: number
	stages: ElementModels.TaxonomyTerm<TaxoStageWithLifeSkill>[]
	years: ElementModels.TaxonomyTerm<TaxoStageYearWithLifeSkill>[]
	outcomes: Outcome[] | ExtendedOutcome[]
	showRelatedOutcome?: boolean
	showAlignedContent?: boolean
	featureLifeSkill?: boolean
	isGroupedByYear?: boolean
	slotBeforeOverarching?: ReactNode
	syllabusCourses?: Course[]
	/**
	 * Lazy aligned content. Default: true
	 */
	lazyAlignedContent?: boolean
}

const stageHeaderGradient = {
	'&:after': {
		// eslint-disable-next-line quotes
		content: "''",
		height: 80,
		position: 'absolute',
		top: 'calc(100% + 2px)',
		left: 0,
		width: '100%',
		backgroundImage:
			'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
	},
}

type SyllabusPaginatedGroup = Omit<
	ElementModels.TaxonomyTerm<TaxoStageYearWithLifeSkill>,
	'codename'
> & {
	isSyllabusCourse?: boolean
	courseCodename?: string
	codename: TaxoStageWithLifeSkill | TaxoStageYear | string
}

const getFormattedPaginatedGroups = (
	group: SyllabusPaginatedGroup,
	syllabusCourses: Course[],
) => {
	return syllabusCourses && syllabusCourses.length > 0
		? syllabusCourses.filter((course) => {
				const courseYearCodename = course.system.codename
				return courseYearCodename === group.courseCodename
		  })
		: []
}

export const OutcomeSlider = (props: OutcomeSliderProps) => {
	const {
		className,
		stages,
		years,
		outcomes,
		pageSize = 3,
		featureLifeSkill,
		showAlignedContent,
		showRelatedOutcome,
		isGroupedByYear = false,
		slotBeforeOverarching,
		lazyAlignedContent = true,
		syllabusCourses,
	} = props

	const [page, setPage] = useState(1)
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})

	const pageIndex = page - 1
	let paginatedGroups = (isGroupedByYear ? years : stages)
		.slice(pageIndex, pageIndex + pageSize)
		.sort((a, b) => {
			const sortFn = isGroupedByYear
				? getFnSortYears()
				: getFnSortStagesOnTaxoStages()
			return sortFn(a, b)
		})
	let needPagination = (isGroupedByYear ? years : stages).length > pageSize

	const handlePrev = () => {
		setPage((prevPage) => prevPage - 1)
	}
	const handleNext = () => {
		setPage((prevPage) => prevPage + 1)
	}

	const syllabusAccordionYearCodenames = syllabusCourses?.flatMap((item) => {
		return item.elements.content_accordion.value.map((a) => a.codename)
	})

	const extensionCodenames = syllabusAccordionYearCodenames.filter(
		(e, i, a) => a.indexOf(e) !== i,
	) as string[]

	const syllabusExtensionCourses =
		syllabusCourses &&
		syllabusCourses.length > 0 &&
		extensionCodenames &&
		extensionCodenames.length > 0
			? syllabusCourses.filter((a) => {
					return extensionCodenames.includes(
						a.elements.stage_years.value[0].codename,
					)
			  })
			: null

	let allColumns = []

	const isExtensionCourse =
		syllabusCourses &&
		syllabusCourses.length > 0 &&
		syllabusExtensionCourses

	if (isExtensionCourse) {
		const notSyllabusCourses = years.filter((year) => {
			return year.codename.match(/^n\d+$/)
		})

		const syllabusExtensionYears = syllabusExtensionCourses.map(
			(item): SyllabusPaginatedGroup => {
				return {
					name: item.elements.stage_years.value[0].name,
					codename:
						item.elements.stage_years.value[0].codename +
						'-' +
						item.elements.slug.value,
					isSyllabusCourse: true,
					courseCodename: item.system.codename,
				}
			},
		)

		// check if has lifeskill
		const lifeSkills = years.some((a) => a.codename === 'life_skills')
			? years.filter((a) => a.codename === 'life_skills')
			: []

		const yearsFilter = years.map((year) => year.codename)

		allColumns = [
			...notSyllabusCourses,
			...syllabusExtensionYears,
			...lifeSkills,
		]
			.flat()
			.filter((group) => {
				return yearsFilter.includes(
					group.codename as TaxoStageYearWithLifeSkill,
				)
			})
			.filter(Boolean) as
			| ElementModels.TaxonomyTerm<TaxoStageWithLifeSkill>[]
			| ElementModels.TaxonomyTerm<TaxoStageYearWithLifeSkill>[]

		paginatedGroups = allColumns
			.slice(pageIndex, pageIndex + pageSize)
			.sort((a, b) => {
				const sortFn = isGroupedByYear
					? getFnSortYears()
					: getFnSortStagesOnTaxoStages()
				return sortFn(a, b)
			})

		needPagination = allColumns.length > pageSize
	}

	const isNextButtonDisabled =
		allColumns.length > 0
			? page === allColumns.length - pageSize + 1
			: page === (isGroupedByYear ? years : stages).length - pageSize + 1

	useEffect(() => {
		setPage(1)
	}, [years])

	return (
		<div className={clsx('relative w-full OutcomeSlider', className)}>
			{/* Previous Button (Desktop) */}
			{needPagination && (
				<div className="hidden lg:block absolute top-120px h-full -left-6 z-10">
					<ButtonIcon
						aria-label="Prev"
						onClick={handlePrev}
						disabled={page === 1}
						className="sticky my-[120px] top-[50%]"
						icon="mdi:chevron-left"
						iconSize={30}
					/>
				</div>
			)}
			{/* to determine whether the stage header becomes sticky or not. !inView means it becomes sticky */}
			<div ref={ref}></div>
			{needPagination && (
				// Mobile Navs
				<div className="flex justify-between lg:hidden relative z-[1]">
					<ButtonIcon
						aria-label="Prev"
						onClick={handlePrev}
						disabled={page === 1}
						icon="mdi:chevron-left"
						iconSize={30}
					/>
					<ButtonIcon
						aria-label="Next"
						onClick={handleNext}
						disabled={isNextButtonDisabled}
						icon="mdi:chevron-right"
						iconSize={30}
					/>
				</div>
			)}
			<div className="sticky top-0 mb-4 bg-white overflow-hidden">
				<GridWrapper>
					{paginatedGroups.map((group) => {
						// Check if groups have syllabusCourses and if they should be split
						const formattedPaginatedGroups =
							getFormattedPaginatedGroups(group, syllabusCourses)

						if (formattedPaginatedGroups.length > 0) {
							return (
								<>
									{formattedPaginatedGroups.map((course) => {
										return (
											<GridCol
												key={course.system.id}
												xs={12 / pageSize}
											>
												<div
													className="bold py-4 border-b-2"
													css={[
														!inView &&
															(stageHeaderGradient as any),
														{
															borderColor:
																'var(--nsw-grey-01)',
															'.is-preview &': {
																top: 26,
															},
														},
													]}
												>
													{group?.codename?.match(
														/((^n\d+)((-\w+)+$)?)/,
													)?.length
														? `Year ${group.name}`
														: `${group.name}`}
													{
														course.elements
															.content_accordion_title
															.value
													}
												</div>
											</GridCol>
										)
									})}
								</>
							)
						}
						return (
							<GridCol key={group.codename} xs={12 / pageSize}>
								<div
									className="bold py-4 border-b-2"
									css={[
										!inView && (stageHeaderGradient as any),
										{
											borderColor: 'var(--nsw-grey-01)',
											'.is-preview &': {
												top: 26,
											},
										},
									]}
								>
									{group?.codename?.match(/^n\d+$/)?.length
										? `Year ${group.name}`
										: group.name}
								</div>
							</GridCol>
						)
					})}
				</GridWrapper>
			</div>
			{slotBeforeOverarching}
			{/* Overarching Outcome(s) */}
			<GridWrapper spacing={4}>
				{outcomes
					?.filter((outcome) => isYes(outcome.elements.isoverarching))
					?.filter((outcome) => {
						// 	if only life skills is selected, show only life skills outcomes
						if (
							years.length === 1 &&
							years[0].codename === 'life_skills'
						) {
							return compareValueWithMultipleChoiceCodename(
								outcome.elements.syllabus_type__items,
								'life_skills',
							)
						}
						if (
							isExtensionCourse &&
							years.length > 0 &&
							!years.find((a) => a.codename === 'life_skills')
						) {
							return !compareValueWithMultipleChoiceCodename(
								outcome.elements.syllabus_type__items,
								'life_skills',
							)
						}
						return true
					})
					.map((outcome) => {
						const hasOutcomeTitle = !!outcome.elements.title.value
						return (
							<GridCol
								key={outcome.system.id}
								display="flex"
								flexDirection="column"
								lg={
									paginatedGroups.length > 1
										? paginatedGroups.length * 4
										: 12
								}
							>
								<OutcomePaper
									className="flex-1"
									data-kontent-item-id={outcome.system.id}
									title={
										<span>
											<span
												className={clsx(
													hasOutcomeTitle && 'mr-3',
												)}
											>
												{outcome.elements.code.value}
											</span>
											{hasOutcomeTitle && (
												<span>
													{
														outcome.elements.title
															.value
													}
												</span>
											)}
										</span>
									}
									outcome={outcome}
									featureLifeSkill={featureLifeSkill}
									showAlignedContent={showAlignedContent}
									lazyAlignedContent={lazyAlignedContent}
								/>
							</GridCol>
						)
					})}
			</GridWrapper>
			<GridWrapper>
				{paginatedGroups.map((group) => {
					const nonOverarchingOutcomes = group.isSyllabusCourse
						? outcomes
								.filter(
									(outcome) =>
										!isYes(outcome.elements.isoverarching),
								)
								?.filter((outcome: ExtendedOutcome) => {
									if (group.codename === 'life_skills') {
										return compareValueWithMultipleChoiceCodename(
											outcome.elements
												.syllabus_type__items,
											'life_skills',
										)
									}
									return outcome.mappedOutcomeSyllabusCode.includes(
										group.courseCodename,
									)
								})
						: (outcomes
								.filter(
									(outcome) =>
										!isYes(outcome.elements.isoverarching),
								)
								?.filter((o) => {
									if (group.codename === 'life_skills') {
										return compareValueWithMultipleChoiceCodename(
											o.elements.syllabus_type__items,
											'life_skills',
										)
									}

									if (isGroupedByYear) {
										return (
											o.elements.stages__stage_years.value.some(
												(y) =>
													y.codename ===
													group.codename,
											) &&
											compareValueWithMultipleChoiceCodename(
												o.elements.syllabus_type__items,
												'mainstream',
											)
										)
									}

									return (
										o.elements.stages__stages.value.some(
											(s) =>
												s.codename === group.codename,
										) &&
										compareValueWithMultipleChoiceCodename(
											o.elements.syllabus_type__items,
											'mainstream',
										)
									)
								}) as Outcome[] | ExtendedOutcome[])

					return (
						<OutcomeNonOverarchingGrid
							key={group.codename}
							outcomes={nonOverarchingOutcomes}
							featureLifeSkill={featureLifeSkill}
							showAlignedContent={showAlignedContent}
							showRelatedOutcome={showRelatedOutcome}
							masonry={paginatedGroups.length === 1}
							pageSize={pageSize}
							lazyAlignedContent={lazyAlignedContent}
							hasSyllabusCourses={true}
						></OutcomeNonOverarchingGrid>
					)
				})}
			</GridWrapper>
			{needPagination && (
				<div className="hidden lg:block absolute top-0 h-full -right-6">
					{/* Next Button (Desktop) */}
					<ButtonIcon
						aria-label="Next"
						onClick={handleNext}
						disabled={isNextButtonDisabled}
						className="sticky my-[120px] top-[50%] "
						icon="mdi:chevron-right"
						iconSize={30}
					/>
				</div>
			)}
		</div>
	)
}
